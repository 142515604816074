@charset "utf-8";

html,
body,
h1,
h2,
h3,
p,
ul,
ol,
li,
table,
tr,
th,
td,
dl,
dt,
dd {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-family: 'Noto Sans JP', sans-serif;
}

a,
li {
    list-style: none;
    text-decoration: none;
    color: #1e1e1e;
}


.App {
    width: 100%;
    height: 100vh;
    font-size: 16px;
    color: #1e1e1e;
}



/* header */

.header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    max-width: 150px;
    z-index: 10;
    height: 100vh;
    position: fixed;
    padding: 60px 0 60px 30px;
}

.header .logo img {
    max-width: 100px;
    height: 100px;
    border-radius: 30%;
    object-fit: cover;
}

nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

nav ul li {
    list-style: none;
    display: inline-block;
    margin-bottom: 40px;
}

nav ul li a {
    text-decoration: none;
    transition: all 0.2s;
    color: #1e1e1e;
    position: relative;
    font-size: 20px;
}

nav ul li a::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #A4B1BD;
    transition: all .3s;
    opacity: 0;
}

nav ul li a:hover::after {
    width: 100%;
    opacity: 1;
}

.burger {
    display: none;
}

/* footer */

.footer {
    text-align: center;
    font-size: 14px;
    padding-bottom: 20px;
}

.footer p {
    display: block;
}


/* scrollEvent */

.scrollEvent {
    width: 60px;
    height: 60px;
    position: fixed;
    z-index: 10;
    top: 90%;
    right: 10%;
    cursor: pointer;
}

.scroll-to-top {
    border-radius: 5px;
    border: #5b5b5b solid 1px;
    display: flex;
    height: 100%;
    align-items: center;
}

.scroll-to-top img {
    display: block;
    width: 50%;
    height: 50%;
    margin: auto;
}



/* top */
.top {
    margin: 0 auto;
    max-width: 1500px;
    padding-top: 90px;
}

.topBack {
    background-color: #E4E3DF;
    width: 100%;
    height: 80vh;
    border-radius: 30px;
    align-items: center;
}

.topBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 855px;
    height: 100%;
    margin: 0 auto;
}

.topImg {
    width: 576px;
}

.topBack img {
    width: 100%;
    height: 241px;
    object-fit: cover;
    border-radius: 30px;
}

.topLink {
    margin: 0;
    padding: 0;
}

.topLink li {
    padding-bottom: 40px;
    font-size: 32px;
}

.topLink li:last-child {
    padding-bottom: 0;
}

.topLink a {
    list-style: none;
    text-decoration: none;
    color: #1e1e1e;
    display: block;
}

.topLink a:hover {
    width: 100%;
    height: 100%;
    background-color: #A4B1BD;
    transition: all .5s;
    transform-origin: left top;
}



/* scroll dawn */
.scrollDawn {
    position: absolute;
    left: 75%;
    width: 50px;
    animation: arrowmove 2s ease-in-out infinite;
}

/*下からの距離が変化して全体が下→上→下に動く*/
@keyframes arrowmove {
    0% {
        bottom: 5%;
    }

    50% {
        bottom: 8%;
    }

    100% {
        bottom: 5%;
    }
}

.scrollDawn .vertical {
    display: block;
    width: 1px;
    height: 150px;
    border: #5b5b5b 1px solid;
}

.scrollDawn .diagonal {
    display: block;
    width: 50px;
    height: 1px;
    border: #5b5b5b 1px solid;
    transform: rotate(-60deg);
    transform-origin: top left;
}



/* contents */

.contents {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 265px;
    padding-left: 100px;
}

.contentsWrap {
    padding-bottom: 200px;
    margin: 0 auto;
}

.contentsWrap h1,
.about h1,
.contact h1 {
    font-size: 32px;
    margin: 0 auto 80px auto;
    width: 130px;
    display: block;
    text-align: center;
    position: relative;
}

.contentsWrap h1::before,
.about h1::before,
.contact h1::before {
    content: '';
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60%;
    background-color: #A4B1BD;
    transform: rotate(-7deg);
    z-index: -1;
}



/* about */

.about {
    padding-bottom: 200px;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.profile {
    padding: 0 4rem 5rem 6rem;
}

.profileText {
    display: flex;
    font-size: 20px;
    line-height: 2rem;
    text-align: start;
}

.aboutWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: start;
}

.aboutWrapper img {
    max-width: 250px;
    height: 250px;
    object-fit: cover;
}

.aboutText {
    margin-top: 30px;
    font-size: 24px;
}

.aboutTextMedia {
    display: none;
    margin-top: 30px;
}




/* products */

#product h2 {
    font-size: 32px;
    margin: 50px 0;
    text-align: center;
}

.productsWrapper,
.productsWrapper-sample {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.productsWrapper li,
.productsWrapper-sample li {
    width: calc(100% / 3);
    padding-right: 20px;
}

.productIcon-sample,
.productIcon {
    width: 100%;
    height: 300px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.productIcon-sample img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.productIcon img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}


.productIcon a {
    display: block;
}

.productText {
    position: absolute;
    text-align: center;
    align-items: center;
    background-color: rgba(91, 91, 91, 0.7);
    bottom: 0;
    color: #fff;
    height: auto;
    display: flex;
    left: 0;
    opacity: 0;
    right: 0;
    top: 0;
    width: 100%;
}

.productIcon-sample:hover .productText,
.productIcon:hover .productText {
    opacity: 1;
}

.productText p {
    width: 100%;
    display: block;
    font-size: 20px;
}

/* others */

.othersWrapper {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.othersWrapper h3 {
    font-size: 24px;
    padding: 0 0 20px 0;
}

.accordionContents {
    display: flex;
    flex-direction: column;
}

.accordionContents .memo {
    text-indent: 3rem;
}

.accordion__item {
    text-align: left;
}

.accordion__item a {
    font-size: 20px;
}




/* contact */

.contact {
    padding-bottom: 60px;
}



.contactWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactWrapper img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

/*線の基点とするためrelativeを指定*/
.contactWrapper p {
    font-size: 20px;
    margin: 0;
    position: relative;
    padding: 10px;
}

.contactWrapper a {
    text-decoration: none;
    color: #5b5b5b;
    transition: all 0.3s;
}

.contactWrapper span {
    background-color: #E4E3DF;
    border-radius: 50px;
    padding: 10px;
    transition: all 0.3s;
}

.contactWrapper span:hover {
    background-color: #A4B1BD;
    transition: all 0.3s;
}

.contactWrapper a:hover {
    color: #E4E3DF;
    transition: all 0.3s;
}


/* ******************************* */
/* ------ work ------ */
.work {
    padding-top: 80px;
    width: 700px;
    margin: 0 auto;
}

.work a {
    text-decoration: none;
    color: #5b5b5b;
}

.swiper {
    width: 700px;
    height: 500px;
}

.slideshow-container {
    width: 100%;
    height: 100%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    color: #1E1E1E;
    border-radius: 80%;
}

/* 丸の色変更 */
.swiper-pagination-bullet-active {
    --swiper-pagination-color: #1E1E1E;
}

.slideshow-container img {
    display: block;
    width: 100%;
    object-fit: cover;
    justify-content: center;
}

.workMain {
    width: 600px;
    margin: 0 auto;
}

.workText h3 {
    font-size: 20px;
    margin: 20px 0;
    text-align: center;
}

.workText p {
    margin-bottom: 0;
}

.page {
    display: flex;
    justify-content: space-around;
    margin: 40px 0;
}

.page img {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 10px;
}

.back a,
.next a,
.noBack,
.noNext {
    display: flex;
    align-items: center;
}

.page .noBack,
.page .noNext {
    opacity: 50%;
}

.page .noBack img,
.page .back img {
    transform: rotate(180deg);
}

/* ******************************* */



/* ********************************************
**********       media
********************************************* */

@media screen and (max-width: 960px) {


    /* top */
    .top {
        padding-top: 50px;
    }

    .topBox {
        flex-direction: column;
    }

    .topImg {
        width: 300px;
    }

    .topBack {
        height: 70vh;
    }

    .topBack img {
        width: 100%;
        height: 100px;
    }

    .topLink li {
        padding-bottom: 20px;
        font-size: 28px;
    }

    /* scroll dawn */
    .scrollDawn {
        left: 80%;
        animation: arrowmove 2s ease-in-out infinite;
    }

    /*下からの距離が変化して全体が下→上→下に動く*/
    @keyframes arrowmove {
        0% {
            bottom: 12%;
        }

        50% {
            bottom: 15%;
        }

        100% {
            bottom: 12%;
        }
    }

    .scrollDawn .vertical {
        height: 100px;
    }

    .scrollDawn .diagonal {
        width: 50px;
    }


    /* header */
    .menu {
        display: none;
    }

    .header {
        flex-direction: row;
        display: flex;
        height: 80px;
        max-width: none;
        width: 100vw;
        background-color: #E4E3DF;
        justify-content: space-between;
        padding: 0 20px;
    }

    .header .logo img {
        height: 60px;
        width: 60px;
    }


    /* Menu */

    .burger {
        display: block;
    }

    .burgerMenu {
        width: 40px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .burger span {
        border: #1e1e1e 1px solid;
        width: 40px;
        display: block;
        position: absolute;
    }

    .burger span:nth-child(1) {
        transition: 0.3s;
        top: 30px;
    }

    .burger p {
        transition: 0.3s;
        top: 40px;
        font-size: 14px;
        text-align: center;
        display: block;
        font-weight: bold;
    }

    .burger span:nth-child(3) {
        transition: 0.3s;
        top: 50px;
    }

    .menuClose {
        top: -120%;
    }

    .menuOpen {
        display: block;
        position: absolute;
        margin-top: 80px;
        background-color: #E4E3DF;
        top: 0;
        right: 0;
        width: 100vw;
        height: 50vh;
        text-align: center;
        transition: all 0.3s ease;
    }

    .menu {
        animation-name: fadeDownAnime;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        opacity: 0;
    }

    @keyframes fadeDownAnime {
        from {
            opacity: 0;
            transform: translateY(-100px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    nav ul {
        height: 100%;
        justify-content: space-around;
    }

    .burgerMenu.close span:nth-child(1) {
        top: 40px;
        transition: 0.3s;
        transform: rotate(45deg);
    }

    .burgerMenu.close p {
        opacity: 0%;
        transition: 0.3s;
    }

    .burgerMenu.close span:nth-child(3) {
        top: 40px;
        transition: 0.3s;
        transform: rotate(-45deg);
    }


    /* contents */
    .contents {
        padding-left: 0;
        padding-top: 150px;
    }

    .contentsWrap {
        padding-bottom: 100px;
    }


    /* about */
    .about {
        padding-bottom: 100px;
    }

    .aboutWrapper {
        flex-direction: column;
    }

    .aboutWrapper span {
        margin: 0 20px;

    }

    .aboutWrapper img {
        max-width: 100px;
        height: 100px;
    }

    .aboutText {
        display: none;
    }

    .aboutTextMedia {
        display: block;
    }

    .profileText:nth-child(3) {
        flex-direction: column;
    }

    /* products */

    .productsWrapper li {
        width: 100%;
        padding: 0;
        margin: 0 30px;
    }

    .work {
        padding-top: 100px;
        width: 90%;
        margin: 0 auto;
    }

    .work a {
        text-decoration: none;
        color: #5b5b5b;
    }

    .work .top {
        display: none;
    }

    .swiper {
        width: 100%;
        height: 400px;
    }

    .slideshow-container {
        width: 100%;
        height: 100%;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        color: #1E1E1E;
        border-radius: 80%;
    }

    /* 丸の色変更 */
    .swiper-pagination-bullet-active {
        --swiper-pagination-color: #1E1E1E;
    }

    .slideshow-container img {
        display: block;
        object-fit: cover;
        justify-content: center;
        height: 100%;
    }

    .workMain {
        width: 100%;
        margin: 0 auto;
    }

    .workText h3 {
        font-size: 20px;
        margin: 20px 0;
        text-align: center;
    }

    .workText p {
        margin-bottom: 0;
    }

    .page {
        display: flex;
        justify-content: space-around;
        margin: 40px 0;
    }

}
